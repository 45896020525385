import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../components/shared-layout";
import StudentCard from "../components/student-card";
import useStores from '../hooks/use-stores';
import { observer, useStaticRendering } from 'mobx-react';
import LoadingPanel from '../components/loading-panel';
import { Link } from 'gatsby';
import TabPanel from "../components/tab-panel";
import { CircularProgress, MenuItem, Select, Tab, Tabs, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";
import IESParallelLine from '../components/charts/iesParallelLine';
import { getPermissionValidity, PermissionCategory } from '../constants/options';

function TabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const Filter = observer(({ callback }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState(0);
  const [semesterDescription, setSemesterDescription] = useState<string>("");
  const [semesterList, setSemesterList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [classCode, setClassCode] = useState<any>(0);
  const DEFAULT_YEAR = new Date().getFullYear().toString();

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
    setSemester(0);
    setSemesterList([]);
    setSemesterDescription(t('ALL'));
    filterStore.getSemesterBasedOnYear(event.target.value as string);
    if (filterStore.semesterList.length > 0) {
      var found = false;
      for (var sem of filterStore.semesterList) {
        setSemester(sem.id);
        setClassCode(0);
        setSemesterList([sem.id])
        var selectedSemester = filterStore.semesterList.find(e => e.id == sem.id);
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        found = true;
        break;
      }
      if (!found) {
        setSemester(0);
        setClassCode(0);
        setSemesterList([]);
      }
    }
  }

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    setYear(DEFAULT_YEAR);
    if (filterStore.academicYearList.length > 0) {
      for (var ay of filterStore.academicYearList) {
        if (ay.yearDescription == DEFAULT_YEAR) {
          setYear(ay.yearDescription);
          filterStore.getSemesterBasedOnYear(ay.yearDescription);
          break;
        }
      }
      filterStore.getCurrentSemester();
      setSemester(filterStore.currentSemester);
      setSemesterList([filterStore.currentSemester]);
      var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
      setSemesterDescription(selectedSemester.semesterDescription);
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
        callback({
          year: DEFAULT_YEAR,
          semester: filterStore.currentSemester,
          semesterList: filterStore.currentSemester,
          semesterDescription: selectedSemester.semesterDescription,
          startDate: startTerm.startDate,
          endDate: endTerm.endDate,
          classCode: filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode),
        });
      }
    }

    // });
  }, []);

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
    setSemesterList([event.target.value as number]);
    setClassCode(0);
    if (event.target.value != 0) {
      var selectedSemester = filterStore.semesterList.find(e => e.id == (event.target.value as number));
      setSemesterDescription(selectedSemester.semesterDescription);
      if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
        var startTerm = selectedSemester.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
        var endTerm = selectedSemester.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
        setStartDate(startTerm.startDate);
        setEndDate(endTerm.endDate);
      }
    } else {
      if (filterStore.semesterList.length > 0) {
        let semList = [filterStore.semesterList[0].id, filterStore.semesterList[1].id];
        setSemesterList(semList);
        setSemesterDescription(t('ALL'));
        // ===== First Sem ===== //
        var firstSem = filterStore.semesterList.find(e => e.id == semList[0]);
        if (firstSem.academicTerms[0].academicTermDates.length > 0) {
          var startTerm = firstSem.academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
          setStartDate(startTerm.startDate);
        }
        // ===== Sec Sem ===== //
        var secSem = filterStore.semesterList.find(e => e.id == semList[1]);
        if (secSem.academicTerms[0].academicTermDates.length > 0) {
          var endTerm = secSem.academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
          setEndDate(endTerm.endDate);
        }
      }
    }
  }

  const onChangeClassCode = (event, child: any) => {
    filterStore.selectedHomeroom = child.props.children;
    if (child.props.value != 0) setClassCode([child.props.value]);
    else setClassCode(0);
  }

  return (
    <div className="shadow-lg rounded-xl border">
      <div className="grid grid-cols-8 gap-4 p-4">
        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            style={{ height: 30 }}
            value={year}
            onChange={onChangeYear}
          >
            {
              filterStore.academicYearList &&
              filterStore.academicYearList.map((ay, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-2 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            style={{ height: 30 }}
            value={semester}
            onChange={onChangeSemster}
            disabled={year == ""}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-3 flex">
          <div className="text-barPrimary mr-2 mt-1">
            {t('HOMEROOM_CODE')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-24 px-2"
            onChange={onChangeClassCode}
            value={classCode}
          >
            <MenuItem value={0}>{t('ALL')}</MenuItem>
            {
              filterStore.filteredHomeRoomCodeList.length > 0 &&
              filterStore.filteredHomeRoomCodeList.map((hr, index) => (
                <MenuItem key={`homeroom_code_list_${hr.homeroomCode}_${index}`} value={hr.homeroomCode}>
                  {hr.homeroomCode}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="w-full text-right">
          <Button
            className="bg-buttonPrimary text-white text-sm"
            onClick={() => {
              callback({
                year,
                semesterList,
                startDate,
                endDate,
                classCode: classCode != 0 ? classCode : filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode),
                semesterDescription
              })
            }}
          >
            {t('SEARCH')}
          </Button>
        </div>
      </div>
    </div>
  );
});

const StudentClassView = observer(() => {
  const { t } = useTranslation();
  const { teacherStore, filterStore } = useStores();
  const [displayFilterTitle, setDisplayFilterTitle] = useState<string>("");
  const [parallelAxis, setParallelAxis] = useState<any>([]);
  const [seriesData, setSeriesData] = useState<any>([]);
  const [legend, setLegend] = useState<any>([]);

  useEffect(() => {
    if (filterStore.academicYearList.length > 0) {
      let currentSemester = filterStore.currentSemester;
      teacherStore.getHomeroomStudentSubjectPerformanceList(39, "11A")
        .then(() => {
          if (teacherStore.hrCodeSubjectPerformList.length > 0) {
            let tempParallelAxis = [];
            for (var i = 0; i < teacherStore.hrCodeSubjectPerformList.length; i++) {
              let tempAxis = {
                name: teacherStore.hrCodeSubjectPerformList[i].subjectCode,
                nameLocation: 'start',
                max: 100,
                min: 0,
                studentNum: teacherStore.hrCodeSubjectPerformList[i].studentPerformance.filter((data) => data.mark > 0).length
              }
              tempParallelAxis.push(tempAxis);
            }

            tempParallelAxis.sort((a, b) => b.studentNum - a.studentNum);
            let tempFilteredParallelAxis = tempParallelAxis.slice(0, 5);
            let index = 0;
            for (var data of tempFilteredParallelAxis) {
              data.dim = index;
              index++;
            }
            tempFilteredParallelAxis.push({
              dim: index,
              name: 'Name',
              type: 'category',
              data: teacherStore.hrCodeSubjectPerformList[0].studentPerformance.map((data) => data.studentName)
            })
            console.log("ParallelAxis", tempFilteredParallelAxis);
            let subjectList = tempFilteredParallelAxis.map((data) => data.name).splice(0, tempFilteredParallelAxis.length - 1);
            console.log("subject class", subjectList);

            setParallelAxis(tempFilteredParallelAxis);

            let tempSeries = [];
            let testIndex = 0;
            for (var student of teacherStore.hrCodeSubjectPerformList[0].studentPerformance.map((data) => data.studentName)) {
              let studentScore = [];

              for (var subject of subjectList) {
                for (var innerSub of teacherStore.hrCodeSubjectPerformList) {
                  if (innerSub.subjectCode == subject) {
                    for (var mark of innerSub.studentPerformance) {
                      if (mark.studentName == student) {
                        studentScore.push(mark.mark);
                        break;
                      }
                    }
                    break;
                  }
                }
              }

              studentScore.push(student);
              let temp = {
                name: student,
                type: 'parallel',
                lineStyle: {
                  width: 1,
                  opacity: 0.5
                },
                data: studentScore,
              }
              tempSeries.push(temp);
              break;
            }
            setSeriesData(tempSeries);
            setLegend(teacherStore.hrCodeSubjectPerformList[0].studentPerformance.map((data) => data.studentName));
            console.log(tempSeries);

          }
        });
    }
  }, [filterStore.academicYearList]);

  const onApplyFilter = (dataInfo: any,) => {
    console.log(dataInfo);
    setDisplayFilterTitle(
      `${t('YEAR')} ${dataInfo.year} - ${dataInfo.semesterDescription} - ${dataInfo.classCode.length > 1 ? `${t('HOMEROOM_CODE')}: ${t('ALL')}` : dataInfo.classCode}`);
  }

  return (
    <>
      <Filter callback={onApplyFilter} />
      <div className="shadow-lg border rounded-xl my-4 p-4">
        <div className="text-barPrimary uppercase font-bold">{displayFilterTitle}</div>
        {
          teacherStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <IESParallelLine
              setting={{
                legend,
                parallelAxis: parallelAxis,
                series: seriesData,
              }}
            />
        }
      </div>
    </>
  )
});

const StudentOverview = observer(({ location }) => {
  const { rootStore, studentStore, filterStore } = useStores();
  const fieldStyle = "text-gray-500 ml-2";
  const { t } = useTranslation();
  const [studentList, setStudentList] = useState<any>();
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [showStudentList, setshowStudentList] = useState(true)
  const [semester, setSemester] = useState<any>([0]);
  const [homeroomCode, setHomeroomCode] = useState(["11A"]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [highlightClassCode, setHightlightClassCode] = useState<string>("");
  const [photo, setPhoto] = useState<string>(
    typeof window !== "undefined" && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO).photo : ""
  );
  const [filterTitle, setFilterTitle] = useState<string>("");

  useEffect(() => { studentStore.loadingOverview = true; }, [])

  useEffect(() => {
    if (filterStore.academicYearList.length > 0) {
      let currentSemester = filterStore.currentSemester;
      let tempSemester = [0];
      if (location.state && location.state.semester && location.state.semester.length > 0
        && location.state.semester[0] != 0) {
        tempSemester = location.state.semester;
      } else {
        tempSemester = [currentSemester];
      }
      if (filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode).length > 0) {
        filterStore.getHomeRoomCodeListBySemesterId(tempSemester[0])
      }
      let allHr = filterStore.filteredHomeRoomCodeList.map(e => e.homeroomCode)

      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(sem => sem.id == tempSemester[0])) {
          let tempFilterTitle = year.yearDescription + " " + year.academicSemesters.find(sem => sem.id == tempSemester[0]).semesterDescription;
          if (location.state && location.state.classCode) {
            tempFilterTitle += ` (${location.state.classCode})`;
            setHightlightClassCode(location.state.classCode);
          }
          setFilterTitle(tempFilterTitle);
          break;
        }
      }
      // console.log("??????????", location.state, tempSemester,);
      setSemester(tempSemester);

      let req: any = {
        semesterIdList: tempSemester,
        homeRoomCode: allHr,
      }
      if (location.state && location.state.domainId) req.domainId = location.state.domainId;
      if (location.state && location.state.subjectClassIdList) req.subjectClassIdList = location.state.subjectClassIdList;

      if (getPermissionValidity(PermissionCategory.STUDENT, "StudentList")) {
        if (getPermissionValidity(PermissionCategory.STUDENT, "StudentList", "AllStudent")) {
          studentStore.getStudentList(req)
            .then((response) => {
              setStudentList(response);
              studentStore.getStudentOverallScoreAttendanceRate({
                semesterIdList: tempSemester,
                uidList: response.studentCards.map((data) => data.uid),
              })
            })
        } else {
          req.teacherUid = JSON.parse(localStorage.USER_INFO).uid;
          studentStore.getStudentList(req)
            .then(response => {
              setStudentList(response);
              studentStore.getStudentOverallScoreAttendanceRate({
                semesterIdList: tempSemester,
                uidList: response.studentCards.map((data) => data.uid)
              })
            })
        }
      }
    }
  }, [filterStore.currentSemester])

  const onApplyFilter = (req: any) => {
    setSemester(req.semesterList);
    setHomeroomCode(req.classCodeFullList);
    setHightlightClassCode("");

    var tempSubjectClassIdList = [];

    if (req.subject !== 0) {
      if (req.subjectClass !== 0) tempSubjectClassIdList.push(req.subjectClass);
      else
        filterStore.subjectClassList.map(e => { tempSubjectClassIdList.push(e.id) });

    }

    var finalReq: any = {
      semesterIdList: req.semesterList,
      homeRoomCode: req.classCodeFullList,
      subjectClassIdList: tempSubjectClassIdList
    };

    if (typeof window !== "undefined" && JSON.parse(localStorage.PERMISSIONS).Student) {
      if (localStorage.USER_INFO && JSON.parse(localStorage.PERMISSIONS).Student.includes("Limited Student Only"))
        finalReq.teacherUid = JSON.parse(localStorage.USER_INFO).uid;
    }

    if (req.domainIdList.length == 1) finalReq.domainId = req.domainIdList[0];

    if (req.semesterList.length > 1) setFilterTitle(req.year + " All Semesters");
    else {
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(sem => sem.id == req.semesterList[0])) {
          setFilterTitle(year.yearDescription + " " + year.academicSemesters.find(sem => sem.id == req.semesterList[0]).semesterDescription);
          break;
        }
      }
    }
    console.log(finalReq, tempSubjectClassIdList);
    studentStore.getStudentList(finalReq)
      .then(response => {
        setStudentList(response);
        studentStore.getStudentOverallScoreAttendanceRate({
          semesterIdList: req.semesterList,
          uidList: response.studentCards.map((data) => data.uid)
        })
      })
  }

  const onChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        showStudentList && <Layout
          onApplyFilter={onApplyFilter}
          showYearSemTerm={true}
          showClassCode={true}
          showDomain={true}
          showSubjectAndSubjectClass={true}
          showStaffList={false}
          showSeachStudent={true}
          staffList={studentList ? studentList.studentCards : []}
          defaultHomeroomCode={"All"}
        >
          <title>Student Dashboard</title>
          {(studentStore.loadingOverview || studentStore.loading) && <LoadingPanel />}
          <div className="col-span-4 flex flex-col bg-white">
            <div className="h-36 flex flex-row rounded-t-xl border border-b-0 mt-2">
              <div className="w-40 p-2 mr-4">
                {photo != "" && <img
                  className="cursor-pointer h-full w-full object-cover rounded-full"
                  src={photo}
                  alt="teacherImg"
                />}
              </div>
              <div className="w-full grid grid-cols-3">
                <div className={`${fieldStyle} mt-6`}>
                  {t('TEACHER')} ID: {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).photo.split("id=")[1]}
                </div>
                <div className={`${fieldStyle} mt-6`}>
                  {t('FIRST_NAME')}: {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).firstName}
                </div>
                <div className={`${fieldStyle} mt-6`}>
                  {t('LAST_NAME')}: {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).lastName}
                </div>
                <div className={`${fieldStyle} mt-6`}>
                  {t('EMAIL')}: {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).email}
                </div>
              </div>
            </div>

            <div className="rounded-b-xl shadow-lg border mb-4 p-2 text-barPrimary font-bold text-base">
              <div>Sudent Dashboard: {filterTitle}</div>
            </div>
            {/* <Tabs
              value={tabValue}
              onChange={onChangeTabValue}
              TabIndicatorProps={{ style: { display: "none" } }}
              className="rounded-b-xl shadow-lg border mb-4"
            >
              <Tab label={t('STUDENT_LIST') + `: ${filterTitle}${location.state && location.state.classCode && ` (${location.state.classCode})`}`} {...TabProps(0, 0 === tabValue)} />
               <Tab label={t('CLASS_VIEW')} {...TabProps(1, 1 === tabValue)} /> 
            </Tabs> */}

            <TabPanel value={tabValue} index={0}>
              <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4" >
                {
                  !studentList || studentList.studentCards.length < 1 ? <div className='text-center text-2xl col-span-3'>{t('NO_STUDENTS_FOUND')}</div>
                    : studentList.studentCards.map((std, index) => (
                      <Link
                        key={`student_card_${index}`}
                        to={`/student-detail/`}
                        state={{ student: std.uid, semester: semester, studentList: studentList.studentCards }}
                      >
                        <StudentCard
                          highlightClassCode={highlightClassCode}
                          setStudentId={(newid) => { setSelectedStudentId(newid); setshowStudentList(false) }}
                          {...std}
                        />
                      </Link>
                    ))}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <StudentClassView />
            </TabPanel>

          </div>
        </Layout>
      }
    </>
  )
})

export default StudentOverview;

